import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import styles from './ToggleInput.module.css';

ToggleInput.defaultProps = {
    className: "",
    checked: false,
    bgOn: "#101928",
    bgOff: "#dee3e7",
};

ToggleInput.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    bgOn: PropTypes.string,
    bgOff: PropTypes.string,
}

function ToggleInput(props) {
    const [checked, setChecked] = useState(props.checked);
    const [focused, setFocused] = useState(false);

    useEffect(()=>{
        setChecked(props.checked);
    }, [props.checked]);

    function onRef(elm){
        if(props.onRef){
            props.onRef(elm);
        }
    }

    function onChange(event){
        if(props.onChange){
            props.onChange(event.target.checked);
        }
        
        setChecked(event.target.checked);
    }

    function onFocus(){
        if(props.onFocus){
            props.onFocus();
        }

        setFocused(true);
    }

    function onBlur(){
        if(props.onBlur){
            props.onBlur();
        }

        setFocused(false);
    }

    let rootClass =  styles.root+ " " +props.className;

    if(focused){
        rootClass = rootClass + " " + styles.focused;
    }

    if(props.error){
        rootClass = rootClass + " " + styles.error;
    }

    if(props.disabled || props.readOnly){
        rootClass = rootClass + " " + styles.disabled;
    }

    let classChecked = "";
    if(checked){
        classChecked = styles.checked;
    }

    let switchStyle = {
        backgroundColor: props.bgOff
    }

    if(checked){
        switchStyle.backgroundColor = props.bgOn;
    }

    return(
        <div className={rootClass} style={props.style}>
            <label className={styles.inputContainer+" "+classChecked}>
                <input 
                    type="checkbox" 
                    checked={checked}
                    readOnly={props.readOnly} 
                    disabled={props.disabled}
                    name={props.name} 
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={(elm)=>{onRef(elm)}} /> 

                <span className={styles.switch} style={switchStyle}></span>
            </label>
        </div>
    );
}



export default ToggleInput;