import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemTrade.module.css';

ItemTrade.defaultProps = {
    view_type: "table", //list
    nr_crt: 0,

    id: "",
    time: 0,
    symbol: "",
    type: "",
    side: "",
    price: 0,
    quantity: 0,
    filled_procent: 0,
    total_usdt: 0,
};

ItemTrade.propTypes = {
    view_type: PropTypes.string,
    nr_crt: PropTypes.number,

    id: PropTypes.string,
    time: PropTypes.number,
    symbol: PropTypes.string,
    type: PropTypes.string,
    side: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    filled_procent: PropTypes.number,
    total_usdt: PropTypes.number,
}

function ItemTrade(props) {

    const datetime = Util.dateToMysqlUtcDatetimeString(new Date(props.time));

    if(props.view_type === "table"){
        return (
            <tr>
                <td>{props.nr_crt}</td>
                <td>{datetime}</td>
                <td>{props.symbol}</td>
                <td>{props.type}</td>
                <td>{props.side}</td>
                <td>{props.price}</td>
                <td>{Util.formatNumber(props.quantity, 0, 8)}</td>
                <td>{props.filled_procent} %</td>
                <td>{Util.formatNumber(props.total_usdt, 0, 0)} USDT</td>
            </tr>
        );
    }else{
        let bgSide = "#2EBC85";
        if(props.side === "SELL"){
            bgSide = "#F5465D";
        }

        return (
            <div className={styles.tradesItem}>
                <div className={styles.content}>
                    <div className={styles.row1}>
                        <div className={styles.nr}>{props.nr_crt}</div>
                        <div className={styles.symbol}>{props.symbol}</div>
                        <div className={styles.side} style={{backgroundColor: bgSide}}>{props.side[0]} {props.filled_procent} %</div>
                        <div className={styles.price}>{props.price}</div>
                        <div className={styles.amount}>{Util.formatNumber(props.quantity, 0, 8)}</div>
                    </div>
                    <div className={styles.row2}>
                        <div className={styles.date}>{datetime}</div>
                        <div className={styles.amount_usd}>USDT {Util.formatNumber(props.total_usdt, 0, 0)}</div>
                    </div>
                </div>
            </div>
        );
    }
    
}


export default ItemTrade;