import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

// import Fetch from "./utils/Fetch";
// import Constants from "./Constants";

import Loader from "./components/Loader";

// import Home from './pages/Home';
import Local from './pages/Local';
import Page404 from './pages/Page404';


function App() {
    return (
        <Init>
            <Router>
                <Switch>
                    
                    <Route exact path="/">
                        <Local />
                    </Route>

                    {/* <Route path="/local">
                        <Local />
                    </Route> */}
                    
                    <Route path="*">
                        <Page404 />
                    </Route>

                </Switch>
            </Router>
        </Init>
    );
}

function Init(props){
    
    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        setLoading(false);

        // let options = {
        //     method: 'POST',
        // }

        // let url = Constants.API_URL+"/api/init.php";
  
        // Fetch.request(url, options)
        //     .then((response) => response.json())
        //     .then((json) => {            
        //         if(json.status === "ok"){
        //             setLoading(false);
        //         }  
        //     })
        //     .catch((error) => {
        //         // console.log(error);
        //     });
    }, []);

    if(loading){
        return (<Loader />);
    }

    return (props.children);

}


export default App;
