import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ItemBalance.module.css';

ItemBalance.defaultProps = {
    view_type: "table", //list
    nr_crt: 0,

    coin: "",

    free: 0,
    locked: 0,
    total: 0,

    free_usdt: 0,
    locked_usdt: 0,
    total_usdt: 0,

    free_bnb: 0,
    locked_bnb: 0,
    total_bnb: 0,

    price_decimals: 0,
    quantity_decimals: 0,
};

ItemBalance.propTypes = {
    view_type: PropTypes.string,
    nr_crt: PropTypes.number,

    coin: PropTypes.string,

    free: PropTypes.number,
    locked: PropTypes.number,
    total: PropTypes.number,

    free_usdt: PropTypes.number,
    locked_usdt: PropTypes.number,
    total_usdt: PropTypes.number,

    free_bnb: PropTypes.number,
    locked_bnb: PropTypes.number,
    total_bnb: PropTypes.number,

    price_decimals: PropTypes.number,
    quantity_decimals: PropTypes.number,
}

function ItemBalance(props) {

    function convertToBnb(){
        if(props.coin === "USDT" || props.coin === "BNB"){
            return;
        }

        if(props.free_bnb > 0.05){
            if(props.convertToBnb){
                props.convertToBnb(props.coin, props.free, props.free_bnb);
            }
        }
        
    }

    function convertToUsdt(){
        if(props.coin === "USDT" || props.coin === "BNB"){
            return;
        }

        if(props.convertToUsdt){
            props.convertToUsdt(props.coin, props.free, props.free_usdt);
        }
    }

    let showBnbIco = false;

    if(props.coin !== "USDT" && props.coin !== "BNB"){
        if(props.free_bnb > 0.05){
            showBnbIco = true;
        }
    }

    if(props.view_type === "table"){
        return (
            <tr {...props}>
                <td onClick={convertToBnb}>
                    {props.coin} 
                    {showBnbIco ? (<BnbIco style={{height: "10px", marginLeft: "6px"}}/>) : null} 
                </td>
                
                <td>{Util.formatNumber(props.free, 0, 0)}</td>
                <td>{Util.formatNumber(props.locked, 0, 0)}</td>
                <td>{Util.formatNumber(props.total, 0, 0)}</td>
                
                <td>{Util.formatNumber(props.free_usdt, 0, 0)}</td>
                <td>{Util.formatNumber(props.locked_usdt, 0, 0)}</td>
                <td onClick={convertToUsdt}>{Util.formatNumber(props.total_usdt, 0, 0)}</td>
            </tr>
        );
    }else{
        return (
            <div {...props} className={styles.balancesItem}>
                <div className={styles.row1}>
                    <div className={styles.col1}>&nbsp;</div>
                    <div className={styles.col2}>Free</div>
                    <div className={styles.col3}>Locked</div>
                    <div className={styles.col4}>Total</div>
                </div>
                <div className={styles.row2}>
                    <div className={styles.col1} style={{fontSize: "16px"}} onClick={convertToBnb}>
                        {props.coin}
                        {showBnbIco > 0 ? (<BnbIco style={{height: "12px", marginLeft: "6px"}}/>) : null} 
                    </div>
                    <div className={styles.col2}>{Util.formatNumber(parseFloat(Util.toFixed(props.free, props.quantity_decimals)), 0, props.quantity_decimals)}</div>
                    <div className={styles.col3}>{Util.formatNumber(parseFloat(Util.toFixed(props.locked, props.quantity_decimals)), 0, props.quantity_decimals)}</div>
                    <div className={styles.col4} style={{fontSize: "16px"}}>{Util.formatNumber(parseFloat(Util.toFixed(props.total, props.quantity_decimals)), 0, props.quantity_decimals)}</div>
                </div>
                <div className={styles.row3}>
                    <div className={styles.col1} onClick={convertToUsdt}>USDT</div>
                    <div className={styles.col2}>$ {Util.formatNumber(parseFloat(Util.toFixed(props.free_usdt, 2)), 0, 2)}</div>
                    <div className={styles.col3}>$ {Util.formatNumber(parseFloat(Util.toFixed(props.locked_usdt, 2)), 0, 2)}</div>
                    <div className={styles.col4}>$ {Util.formatNumber(parseFloat(Util.toFixed(props.total_usdt, 2)), 0, 2)}</div>
                </div>
            </div>
        );
    }
    
}


function BnbIco(props){
    return(
        <svg {...props} viewBox="0 0 2500.01 2500">
            <g>
                <path fill="#f3ba2f" d="M764.48,1050.52,1250,565l485.75,485.73,282.5-282.5L1250,0,482,768l282.49,282.5M0,1250,282.51,967.45,565,1249.94,282.49,1532.45Zm764.48,199.51L1250,1935l485.74-485.72,282.65,282.35-.14.15L1250,2500,482,1732l-.4-.4,282.91-282.12M1935,1250.12l282.51-282.51L2500,1250.1,2217.5,1532.61Z"/>
                <path fill="#f3ba2f" d="M1536.52,1249.85h.12L1250,963.19,1038.13,1175h0l-24.34,24.35-50.2,50.21-.4.39.4.41L1250,1536.81l286.66-286.66.14-.16-.26-.14"/>
            </g>
        </svg>
    );
}


export default ItemBalance;