import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loader.module.css';

Loader.defaultProps = {
    text: "",
};

Loader.propTypes = {
    text: PropTypes.string,
}

function Loader(props) {
    return (
        <div {...props} className={styles.loader}></div>
    );
}


export default Loader;