import React from 'react';

import styles from './Page404.module.css';

function Page404() {

    return (
        <div className={styles.root}>
            <div className="center-wrapper">
                <div className="center">404<br/>Not Found</div>
            </div>
        </div>
    );
}

export default Page404;

