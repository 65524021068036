import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from "react-router-dom";

import Loader from "../../components/Loader";
import ItemBalance from "../../components/ItemBalance";
import ItemTrade from "../../components/ItemTrade";
import ItemBalanceHistory from "../../components/ItemBalanceHistory";
import ToggleInput from "../../components/ToggleInput";

import Util from "../../utils/Util";
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './Local.module.css';

const puDayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Local() {

    const query = useQuery();
    const [loading, setLoading] = useState(true);
    const [timeline, setTimeline] = useState("day");

    const [userBalances, setUserBalances] = useState([]);
    const [userTrades, setUserTrades] = useState([]);
    const [userBalanceHistory, setUserBalanceHistory] = useState([]);

    const [systemStatus, setSystemStatus] = useState("");

    const [exchangeInfo, setExchangeInfo] = useState({});

    const [masterSwitch, setMasterSwitch] = useState(false);

    const queryObj = useRef({
        showTrades: 0,
    }); 


    useEffect(()=>{
        let showTrades = query.get("showTrades");
        if(showTrades != null){
            queryObj.current.showTrades = showTrades;
        }

        // document.title = Constants.PAGE_NAME+" - Local";

        if(!localStorage.timeline) {
            localStorage.timeline = "day";
        }
        setTimeline(localStorage.timeline);

        getData();

        return ()=>{
            //console.log("unmount");
        }
    }, []);


    function getData(){

        setLoading(true); 

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/local.php?timeline="+localStorage.timeline;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {    

                //master switch
                if(json.master_switch === "1"){
                    setMasterSwitch(true)
                }else{
                    setMasterSwitch(false)
                }

                //exchange info
                let exchange_info_obj = {};
                if(json.exchange_info.symbols && Array.isArray(json.exchange_info.symbols)){    
                    let symbols = json.exchange_info.symbols;
                    for(let i = 0; i < symbols.length; i++){
                        let symbol = symbols[i].symbol;
                        let filters = symbols[i].filters;
    
                        exchange_info_obj[symbol] = {};
                        exchange_info_obj[symbol].can_trade = false;
    
                        if(symbols[i].status === "TRADING" && symbols[i].isSpotTradingAllowed === true){
                            if(symbols[i].orderTypes.indexOf('LIMIT') !== -1 && symbols[i].orderTypes.indexOf('MARKET') !== -1){
                                exchange_info_obj[symbol].can_trade = true;
                            }
                        }
    
                        for(let j = 0; j < filters.length; j++){
                            let filter = filters[j];
    
                            if(filter.filterType === "PRICE_FILTER"){
                                exchange_info_obj[symbol].min_trade_price = parseFloat(filter.minPrice);
                            }
    
                            if(filter.filterType === "LOT_SIZE"){
                                exchange_info_obj[symbol].min_trade_quantity = parseFloat(filter.minQty);
                            }
    
                            if(filter.filterType === "MIN_NOTIONAL"){
                                exchange_info_obj[symbol].min_trade_value = parseFloat(filter.minNotional);
                            }
                        }
                    } 
                }
                setExchangeInfo(exchange_info_obj);
                
                //tickers
                var tickers = {};

                for(let i = 0; i < json.tickers.length; i++){
                    var ticker = json.tickers[i];
                    tickers[ticker.symbol] = parseFloat(ticker.price)
                }

                //balances
                var balances = {};

                for(let i = 0; i < json.balances.balances.length; i++){
                    var balance = json.balances.balances[i];
                    balances[balance.asset] = {
                        free: parseFloat(balance.free),
                        locked: parseFloat(balance.locked)
                    }
                }


                //user balances
                let userBalances = [];
                let coins = Object.keys(balances);

                for(let i = 0; i < coins.length; i++){
                    let coin = coins[i];

                    let symbol = coin+"USDT";
                    let balanceFree = 0;
                    let balanceLocked = 0;

                    let priceDecimals = 0;
                    let quantityDecimals = 0;

                    if(symbol === "USDTUSDT"){
                        balanceFree = balances[coin].free;
                        balanceLocked = balances[coin].locked;
                    }else if(tickers[symbol]){
                        balanceFree = tickers[symbol] * balances[coin].free;
                        balanceLocked = tickers[symbol] * balances[coin].locked;

                        if(exchange_info_obj[symbol]){
                            priceDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_price);
                            quantityDecimals = Util.getDecimalsCount(exchange_info_obj[symbol].min_trade_quantity);
                        }
                    }else{
                        continue;
                    }

                    let balanceTotal = balanceFree+balanceLocked;

                    if(balanceTotal < 10){ //dolars
                        continue;
                    }

                    //for bnb
                    let symbol2 = coin+"BNB";
                    let balanceFree2 = 0;
                    let balanceLocked2 = 0;

                    if(symbol2 === "BNBBNB"){
                        balanceFree2 = balances[coin].free;
                        balanceLocked2 = balances[coin].locked;
                    }else if(tickers[symbol2]){
                        balanceFree2 = tickers[symbol2] * balances[coin].free;
                        balanceLocked2 = tickers[symbol2] * balances[coin].locked;
                    }

                    let balanceTotal2 = balanceFree2+balanceLocked2;

                    userBalances.push({
                        coin: coin,

                        free: balances[coin].free,
                        locked: balances[coin].locked,
                        total: balances[coin].free+balances[coin].locked,

                        free_usdt: balanceFree,
                        locked_usdt: balanceLocked,
                        total_usdt: balanceTotal,

                        free_bnb: balanceFree2,
                        locked_bnb: balanceLocked2,
                        total_bnb: balanceTotal2,

                        price_decimals: priceDecimals,
                        quantity_decimals: quantityDecimals
                    });
                }

                for(let i = 0; i < userBalances.length; i++){
                    let item = userBalances[i];
                    if(item.coin === "BNB"){
                        continue;
                    }

                    let symbol = item.coin+"BNB";

                    for(let j = 0; j < json.exchange_info.symbols.length; j++){
                        let item2 = json.exchange_info.symbols[j];

                        if(item2.symbol === symbol){
                            if(item2.status !== "TRADING"){
                                item.free_bnb = 0;
                                item.locked_bnb = 0;
                                item.total_bnb = 0;
                            }
                        }
                        
                    }
                }

                setUserBalances(userBalances);


                //user trades
                let userTrades = [];

                for(let i = 0; i < json.open_orders.length; i++){
                    let order = json.open_orders[i];

                    userTrades.push({
                        id: order.orderId.toString(),
                        time: order.time,
                        symbol: order.symbol,
                        type: order.type,
                        side: order.side,
                        price: parseFloat(order.price),
                        quantity: parseFloat(order.origQty),
                        filled_procent: parseInt((parseFloat(order.executedQty)/parseFloat(order.origQty))*100),
                        total_usdt: parseFloat(order.price)*parseFloat(order.origQty),
                    });
                }

                setUserTrades(userTrades);

                //user balance history
                let userBalanceHistory = [];

                for(let i = 0; i < json.balances_history.length; i++){
                    let balance = json.balances_history[i];
                    let balanceNext = null;
                    if(json.balances_history[i+1]){
                        balanceNext = json.balances_history[i+1];
                    }

                    let date_obj = new Date(parseInt(balance.date_create_ts)*1000);
                    let day = puDayOfWeek[date_obj.getDay()];
                    let date = balance.date_create;
                    let symbol = balance.symbol;
                    let amount = parseInt(balance.amount);
                    let revenue = 0;
                    if(balanceNext){
                        revenue = amount - parseInt(balanceNext.amount);
                    }

                    userBalanceHistory.push({
                        day: day,
                        date: date,
                        symbol: symbol,
                        amount: amount,
                        revenue: revenue,
                    });
                }

                setUserBalanceHistory(userBalanceHistory);

                setSystemStatus(json.system_status);

                setLoading(false); 
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    function clickTimeline(value){
        localStorage.timeline = value;
        setTimeline(localStorage.timeline);
        getData();
    }

    function restartMirror(){
        let pass = prompt("System will be restarted. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/restartMirror.php?password="+pass;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }


    function stopMirror(){
        let pass = prompt("System will be shutdown. Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }


        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/stopMirror.php?password="+pass;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setLoading(true);
                    setTimeout(()=>{
                        getData();
                    }, 10000);
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });

    }

    function sellCoin(coin, coin_dest, free, free_dest){
        if(coin === "USDT" || coin === "BNB"){
            return;
        }

        if(!exchangeInfo[coin+coin_dest].can_trade){
            alert("Cannot trade "+coin+coin_dest+" at this moment");
            return;
        }

        if(exchangeInfo[coin+coin_dest].min_trade_value > free_dest){
            alert("Cannot trade "+coin+coin_dest+": No minimum trade value");
            return;
        }

        let priceDecimals = Util.getDecimalsCount(exchangeInfo[coin+coin_dest].min_trade_price);
        let quantityDecimals = Util.getDecimalsCount(exchangeInfo[coin+coin_dest].min_trade_quantity);

        let amountSell = Util.toFixed(free, quantityDecimals);
        let forAmount = Util.toFixed(free_dest, priceDecimals);

        let pass = prompt("Sell "+coin+" "+amountSell+" = "+forAmount+" "+coin_dest+". Enter password to confirm");
        if (pass === null) {
            return;
        }

        pass = pass.trim();
        if(pass === ""){
            alert("Error");
            return;
        }

        let options = {
            method: 'GET',
        }

        let url = Constants.API_URL+"/api/sellCoin.php?password="+pass+"&coin="+coin+"&coin_dest="+coin_dest+"&quantity="+amountSell;
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    getData();
                }else{
                    alert("Error");
                }  
            })
            .catch((error) => {
                alert("Error");
            });
    }

    function convertToBnb(coin, free, free_dest){
        sellCoin(coin, "BNB", free, free_dest)
    }

    function convertToUsdt(coin, free, free_dest){
        sellCoin(coin, "USDT", free, free_dest)
    }

    function onChangeMasterSwitch(value){

        let checked = 0;
        if(value){
            checked = 1;
        }

        let url = Constants.API_URL+"/api/masterSwitch.php?value="+checked;
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {            
                if(json.status === "ok"){
                    setMasterSwitch(value);
                } 
            })
            .catch((error) => {
                alert("Error");
            });
    }

    let timelineDayCss = "";
    let timelineMonthCss = "";

    if(timeline === "day"){
        timelineDayCss = styles.active;
    }

    if(timeline === "month"){
        timelineMonthCss = styles.active;
    }

    let masterSwitchLabel = "NO";
    if(masterSwitch){
        masterSwitchLabel = "YES";
    }

    if(loading){
        return (<Loader />);
    }

    return (
        <div className={styles.root}>

            <div className={styles.mobile}>
                <div className={styles.title}>Balances</div>
                <div className={styles.balances}>
                    {userBalances.map((row, index) => {
                        return (
                            <ItemBalance 
                                key={row.coin}  
                                view_type="list" 
                                nr_crt={index+1} 
                                coin={row.coin} 
                                free={row.free} 
                                locked={row.locked} 
                                total={row.total} 
                                free_usdt={row.free_usdt} 
                                locked_usdt={row.locked_usdt} 
                                total_usdt={row.total_usdt}
                                free_bnb={row.free_bnb} 
                                locked_bnb={row.locked_bnb} 
                                total_bnb={row.total_bnb}
                                convertToBnb={convertToBnb}
                                convertToUsdt={convertToUsdt}
                                price_decimals={row.price_decimals}
                                quantity_decimals={row.quantity_decimals}
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>Enter new trades</div>

                <div style={{display: "inline-block", width:"100%", paddingLeft: "16px", paddingRight: "16px", marginTop: "20px"}}>
                    <div style={{float: "left", marginTop: "7px"}}>Mirror Trades: {masterSwitchLabel}</div>
                    <div style={{float: "right"}}>
                        <ToggleInput bgOff="#434C5A" bgOn="#EFBA0C" onChange={onChangeMasterSwitch} checked={masterSwitch} />
                    </div>
                </div>

                {queryObj.current.showTrades == 1 ?
                <div>
                    <div className={styles.title}>Open orders</div>
                    <div className={styles.trades}>
                        {userTrades.map((row, index) => {
                            return (
                                <ItemTrade 
                                    key={row.id}  
                                    view_type="list" 
                                    nr_crt={index+1} 
                                    id={row.id}
                                    time={row.time}
                                    symbol={row.symbol}
                                    type={row.type}
                                    side={row.side}
                                    price={row.price}
                                    quantity={row.quantity}
                                    filled_procent={row.filled_procent}
                                    total_usdt={row.total_usdt}
                                />
                            )
                        })}
                    </div>
                </div>
                :null}

                <div className={styles.title}>
                    Balance history 
                    <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}} style={{marginLeft: "20px"}}>1D</span> 
                    <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>
                </div>
                <div className={styles.balanceHistory}>
                    {userBalanceHistory.map((row, index) => {
                        return (
                            <ItemBalanceHistory 
                                key={index}  
                                view_type="list" 
                                nr_crt={index+1} 
                                day={row.day} 
                                date={row.date} 
                                symbol={row.symbol} 
                                amount={row.amount} 
                                revenue={row.revenue} 
                            />
                        )
                    })}
                </div>

                <div className={styles.title}>
                    System is {systemStatus} <br/>
                    <span className={styles.actionBtn} onClick={restartMirror}>Restart Mirror</span> 
                    <span className={styles.actionBtn} onClick={stopMirror}>Stop Mirror</span> 
                </div>
            </div>

            <div className={styles.desktop}>
                <table>
                    <caption>Balances</caption>
                    <thead>
                        <tr>
                            <th>Coin</th>

                            <th>Qty. Free</th>
                            <th>Qty. Locked</th>
                            <th>Qty. Total</th>

                            <th>$ Free</th>
                            <th>$ Locked</th>
                            <th>$ Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userBalances.map((row, index) => {
                            return (
                                <ItemBalance 
                                    key={row.coin}  
                                    view_type="table" 
                                    nr_crt={index+1} 
                                    coin={row.coin} 
                                    free={row.free} 
                                    locked={row.locked} 
                                    total={row.total} 
                                    free_usdt={row.free_usdt} 
                                    locked_usdt={row.locked_usdt} 
                                    total_usdt={row.total_usdt}

                                    free_bnb={row.free_bnb} 
                                    locked_bnb={row.locked_bnb} 
                                    total_bnb={row.total_bnb}
                                    convertToBnb={convertToBnb}
                                    convertToUsdt={convertToUsdt}
                                />
                            )
                        })}
                    </tbody>
                </table>

                {/* <table>
                    <caption>Open trades</caption>
                    <thead>
                        <tr>
                            <th>Nr.</th>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Filled</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userTrades.map((row, index) => {
                            return (
                                <ItemTrade 
                                    key={row.id}  
                                    view_type="table" 
                                    nr_crt={index+1} 
                                    id={row.id}
                                    time={row.time}
                                    symbol={row.symbol}
                                    type={row.type}
                                    side={row.side}
                                    price={row.price}
                                    quantity={row.quantity}
                                    filled_procent={row.filled_procent}
                                    total_usdt={row.total_usdt}
                                />
                            )
                        })}
                    </tbody>
                </table> */}

                <table style={{width: "640px", marginTop: "100px"}}>
                    <caption>
                        Balance history 
                        <span className={styles.timeline+" "+timelineDayCss} onClick={()=>{clickTimeline("day")}}>1D</span> 
                        <span className={styles.timeline+" "+timelineMonthCss} onClick={()=>{clickTimeline("month")}}>1M</span>
                    </caption>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Symbol</th>
                            <th>Amount</th>
                            <th>Revenue</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userBalanceHistory.map((row, index) => {
                            return (
                                <ItemBalanceHistory 
                                    key={index}  
                                    view_type="table" 
                                    nr_crt={index+1} 
                                    day={row.day} 
                                    date={row.date} 
                                    symbol={row.symbol} 
                                    amount={row.amount} 
                                    revenue={row.revenue} 
                                />
                            )
                        })}
                    </tbody>
                </table>

                <div className={styles.actions} style={{width: "640px", marginTop: "100px"}}>
                    System is {systemStatus}
                    <span className={styles.btn} onClick={restartMirror}>Restart Trade</span> 
                    <span className={styles.btn} onClick={stopMirror}>Stop Trade</span> 
                </div>
            </div>
            
        </div>
    );
}

export default Local;


