module.exports = {
    formatNumber(value, min_decimals = 2, max_decimals = 2) {

        // if(value === 0){
        //     return value.toLocaleString('ja-JP', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        // }

        return value.toLocaleString('ja-JP', {minimumFractionDigits: min_decimals, maximumFractionDigits: max_decimals});

    },

    mysqlUtcDatetimeStringToDate(utcDateTimeString) {
        if(utcDateTimeString === "0000-00-00 00:00:00"){
            return null;
        }

        if(utcDateTimeString.indexOf("T") !== -1 && utcDateTimeString.indexOf(".") !== -1){
            // console.log(utcDateTimeString);
            // console.log(new Date(utcDateTimeString));

            return new Date(utcDateTimeString);
        }

        utcDateTimeString = utcDateTimeString.trim();
        utcDateTimeString = utcDateTimeString.replace(" ", "T");
        utcDateTimeString = utcDateTimeString+".000Z";

        // console.log(utcDateTimeString);
        // console.log(new Date(utcDateTimeString));

        return new Date(utcDateTimeString);
    },

    dateToMysqlUtcDatetimeString(date) {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    },
    
    toFixed(nr, decimals = 0){
        nr = parseFloat(nr);
    
        if(isNaN(nr)){
            throw 'Parameter nr is not a number in toFixed(nr, decimals = 0) function !';
        }
    
        let isInteger = false;
        if(nr == parseInt(nr)){
            isInteger = true;
        }
    
        let nrString = nr.toString();
    
        //if integer
        if(isInteger){
            if(decimals > 0){
                nrString += ".";
                for(let i = 0; i < decimals; i++){
                    nrString += "0";
                }
            }
        }else{
            //if float
            if(decimals > 0){
    
                let decimalsString = ".";
                let existenDecimalsCount = 0;
                let startExistenDecimalsCount = false;
    
                for(let i = 0; i < nrString.length; i++){
                    if(nrString[i] == "."){
                        startExistenDecimalsCount = true;
                        continue;
                    }
    
                    if(startExistenDecimalsCount){
                        existenDecimalsCount++;
    
                        if(existenDecimalsCount <= decimals){
                            decimalsString += nrString[i];
                        }
                    }
                    
                }
    
                for(let i = 0; i < (decimals - existenDecimalsCount); i++){
                    decimalsString += "0";
                }
    
                nrString = parseInt(nrString).toString()+decimalsString;
    
            }else{
                nrString = parseInt(nrString).toString();
            }
    
        }
    
        return nrString;
    
    },

    getDecimalsCount(value){
        value = parseFloat(value);
    
        if(isNaN(value)){
            return 0;
        }
    
        value = value.toLocaleString('ja-JP', {minimumFractionDigits: 20, maximumFractionDigits: 20})
    
        let startCount = false;
        let count = 0;
    
        for(let i = value.length-1; i >= 0; i--){
            if(value[i] == '.'){
                break;
            }
    
            if(value[i] != '0'){
                startCount = true;
            }
    
            if(startCount){
                count++;
            }
        }
    
        return count;
    }
}